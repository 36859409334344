<template>
  <div class="ApplicationSortForm-wrapper">
    <form-panel
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block>
          <el-form-item label="所属公司" prop="regionId" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
            <v-select2 v-model="form.regionId" placeholder="输入公司名称" v-bind="regionParams" :width="width" />
          </el-form-item>
          <el-form-item label="所属项目" prop="communityId" :rules="[{ required: true, message: '请选择所属项目', trigger: 'change' }]">
            <v-select2 ref="communityRef" v-model="form.communityId" placeholder="输入项目名称" v-bind="communityParams" :subjoin="subjoin" :width="width" @onChange="communityChange" />
          </el-form-item>
          <el-form-item label="应用版块" :rules="[{ required: true, message: '请选择应用版块', trigger: 'change' }]" prop="sectionId">
            <v-select v-model="form.sectionId" :options="sectionOps" :width="width" @change="sectionChange" />
          </el-form-item>
          <el-form-item label="排序">
            <div v-show="sortVisible" class="sort">
              <SlickList class="list" v-model="form.subIds" helperClass="list-item-active" lockAxis="y">
                <SlickItem class="list-item" v-for="(item, index) in form.subIds" :index="index" :key="index">
                  {{ item.subName }}
                </SlickItem>
              </SlickList>
            </div>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { Col2Detail, Col2Block } from 'components/bussiness'
import { getSectionURL, getGrantedApplicationListURL, applicationSortURL } from './api'
import { regionParams, communityParams } from 'common/select2Params'
import { SlickList, SlickItem } from 'vue-slicksort'

export default {
  name: 'ApplicationSortForm',
  components: {
    Col2Detail,
    Col2Block,
    SlickList,
    SlickItem
  },
  data () {
    return {
      width: 182,
      userType: undefined,
      regionParams,
      communityParams,
      sectionOps: [],
      form: {
        regionId: undefined,
        communityId: undefined,
        sectionId: undefined,
        subIds: []
      },
      submitConfig: {
        submitUrl: applicationSortURL,
        submitMethod: 'PUT'
      }
    }
  },
  created () {
    this.$setBreadcrumb('设置版块应用排序')
    this.userType = this.$store.state.userInfo.userType
  },
  computed: {
    sortVisible () {
      const subIds = this.form.subIds
      return subIds && subIds.length
    },
    subjoin () {
      return {
        regionId: this.form.regionId,
        isNormal: 1
      }
    }
  },
  methods: {
    async setSectionOps (communityId) {
      const params = {
        sectionLevel: 1,
        communityId
      }
      const { status, data } = await this.$axios.get(getSectionURL, { params })
      if (status === 100) {
        let sectionOps = []
        if (Array.isArray(data) && data.length) {
          data.forEach(item => {
            sectionOps.push({
              text: item.name,
              value: item.id
            })
          })
        }
        this.sectionOps = sectionOps
      }
    },
    clearSectionOps () {
      this.sectionOps = []
      this.form.sectionId = undefined
    },
    async setSubIds (params) {
      const { status, data } = await this.$axios.get(getGrantedApplicationListURL, { params })
      if (status === 100) {
        this.form.subIds = data || []
      }
    },
    clearSubIds () {
      this.form.subIds = []
    },
    communityChange (option) {
      const communityId = option && option.id
      if (communityId) {
        this.setSectionOps(communityId)
      } else {
        this.clearSectionOps()
      }
    },
    sectionChange (section) {
      if (section) {
        const params = {
          communityId: this.form.communityId,
          sectionId: section
        }
        this.setSubIds(params)
      } else {
        this.clearSubIds()
      }
    },
    submitBefore (data) {
      let subIds = []
      data['subIds'].forEach(item => {
        subIds.push(item.id)
      })
      data['subIds'] = subIds

      if (this.$refs.communityRef) {
        let dataObject = `${this.$refs.communityRef.getBackTextValue()}`
        data.dataObject = dataObject
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.ApplicationSortForm-wrapper {
  .sort {
    .list {
      width: 182px;
      max-height: 500px;
      overflow: auto;
      background-color: #f3f3f3;
      border: 1px solid #efefef;
      .list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: #fff;
        border-bottom: 1px solid #efefef;
      }
    }
  }
}
.list-item-active {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #fff;
  background-color: #1B8CF2;
}
</style>
