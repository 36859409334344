var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ApplicationSortForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          { attrs: { form: _vm.form, submitBefore: _vm.submitBefore } },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属公司",
                        prop: "regionId",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属公司",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "输入公司名称",
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.regionId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "regionId", $$v)
                              },
                              expression: "form.regionId",
                            },
                          },
                          "v-select2",
                          _vm.regionParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        prop: "communityId",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属项目",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            ref: "communityRef",
                            attrs: {
                              placeholder: "输入项目名称",
                              subjoin: _vm.subjoin,
                              width: _vm.width,
                            },
                            on: { onChange: _vm.communityChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "应用版块",
                        rules: [
                          {
                            required: true,
                            message: "请选择应用版块",
                            trigger: "change",
                          },
                        ],
                        prop: "sectionId",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.sectionOps, width: _vm.width },
                        on: { change: _vm.sectionChange },
                        model: {
                          value: _vm.form.sectionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sectionId", $$v)
                          },
                          expression: "form.sectionId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "排序" } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sortVisible,
                            expression: "sortVisible",
                          },
                        ],
                        staticClass: "sort",
                      },
                      [
                        _c(
                          "SlickList",
                          {
                            staticClass: "list",
                            attrs: {
                              helperClass: "list-item-active",
                              lockAxis: "y",
                            },
                            model: {
                              value: _vm.form.subIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "subIds", $$v)
                              },
                              expression: "form.subIds",
                            },
                          },
                          _vm._l(_vm.form.subIds, function (item, index) {
                            return _c(
                              "SlickItem",
                              {
                                key: index,
                                staticClass: "list-item",
                                attrs: { index: index },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.subName) +
                                    "\n              "
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }